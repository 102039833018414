import React from "react";
import AdminLayout from "../../../components/admin/adminLayout";
import ToolsComponent from "../../../components/admin/toolsComponent";
import SEO from "../../../components/seo";
import WithPrivateRoute from "../../../components/admin/withPrivateRoute";

const Tools = (props) =>
{
  return (
    <AdminLayout>
      <SEO
        keywords = { [`LawChest`, `admin`, `tools`, `administration`] }
        title = "Tools"
        isAdmin = { true }
      />
      <ToolsComponent />
    </AdminLayout>
  );
};

export default WithPrivateRoute(Tools);