import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Grid from "../grid";
import { toolImages, defaultToolIllustration } from "../../utils/gridImages";

const ToolsComponent = () =>
{
  const { site } = useStaticQuery(
    graphql`
      query
      {
        site
        {
          siteMetadata
          {
            tools
            {
              id
              name
              description
              link
            }
          }
        }
      }
    `
  );

  return (
    <section
      id = "utilitySection"
      className = "text-center"
    >
      <h2 className="bg-yellow-400 text-2xl font-bold inline-block mb-8 p-3">
        Choose a tool to update
      </h2>

      <Grid
        gridElements = { site.siteMetadata.tools }
        elementImages = { toolImages }
        defaultElementImage = { defaultToolIllustration }
        isAdminPage = { true }
      />
    </section>
  );
};

export default ToolsComponent;